<template>
	<div>
		<!-- 角色管理 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="openDrawer()">发布通知</el-button>
		</div>
		<template>
		
			<div>
				<el-table :max-height="height" :data="tableData" border stripe v-loading="loading" size="mini">
					<el-table-column width="150px" prop="title" label="标题" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column width="250px" prop="content" label="内容" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column label="商户" align="left">
						<template slot-scope="scope">
							  <el-button type="primary" size="mini" plain  v-if="scope.row.business_name">{{scope.row.business_name}}</el-button>
						</template>
					</el-table-column>
					<el-table-column label="通道" align="left">
						<template slot-scope="scope">
							<el-button type="success" size="mini" plain v-if="scope.row.payway_name">{{scope.row.payway_name}}</el-button>
						</template>
					</el-table-column>
					<el-table-column prop="operator" label="发送人" align="center"></el-table-column>
					<el-table-column prop="add_time" label="发送时间" align="center"></el-table-column>
					<el-table-column label="发送状态" align="left">
						<template slot-scope="scope">
							<el-tag size="mini" v-if="scope.row.state == 1" type="success">{{scope.row.notice_status_msg}}</el-tag>
							<el-tag size="mini" v-if="scope.row.state == 2" type="danger">{{scope.row.notice_status_msg}}</el-tag>
							<el-tag size="mini" v-if="scope.row.state == 3" type="warning">{{scope.row.notice_status_msg}}</el-tag>
						</template>
					</el-table-column>
					
					<el-table-column prop="" :label="$t('main.passagewayMenu.operation')" align="center">
						<template slot-scope="scope" class="flexX flexcenter">
							  <!-- <el-button class="mr10 mb10" size="mini" @click="resendMessage(scope.row.id)">重发</el-button> -->
							  <el-link type="primary" @click="resendMessage(scope.row.id)">重发</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<el-drawer
		size="50%"
		  title="发布通知"
		  :visible.sync="drawer"
		  :direction="direction"
		  :before-close="handleClose">
		  <div class="drawer-container">
			  <div v-loading="dialogloading"  class="drawer-content">
				<el-form class="formBox" status-icon :label-position="labelPosition">
				  <el-form-item label="标题">
						<el-input size="mini" v-model="title" autocomplete="off"></el-input>
				  </el-form-item>
				  <el-form-item label="图片">
						<el-upload
						  size="mini"
						  class="upload-demo"
						  ref="upload"
						  drag
						  action=""
						  :auto-upload="false"
						  :limit="1"
						  :before-upload="beforeUpload"
						  :on-change="handleChange">
						  <i class="el-icon-upload"></i>
						  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						  <div class="el-upload__tip" slot="tip">只能上传一张图片，且不超过500kb</div>
						</el-upload>
				  </el-form-item>
				  <el-form-item label="内容">
						<el-input size="mini" type="textarea" rows="6" v-model="content" autocomplete="off" show-word-limit></el-input>
				  </el-form-item>
				  
				  <el-form-item label="商户">
						<el-select size="mini" v-model="traderSed" multiple placeholder="请选择">
						  <el-option
							v-for="item in traders"
							:key="item.id"
							:label="item.business_name"
							:value="item.id"
							:disabled="item.is_able==1?false:true">
						  </el-option>
						</el-select>
				  </el-form-item>
				  
				  <el-form-item label="通道">
						<el-select size="mini" v-model="paywaySed" multiple placeholder="请选择">
						  <el-option
							v-for="item in payways"
							:key="item.id"
							:label="item.name"
							:value="item.id"
							:disabled="item.is_able==1?false:true">
						  </el-option>
						</el-select>
				  </el-form-item>
				  
				  <el-form-item label="是否随机@群成员:">
						<el-checkbox v-model="checked"></el-checkbox>
				  </el-form-item>
					
				</el-form>
				
			  </div>
			  <div class="textCenter drawer-footer">
			  	<el-button size="mini" type="primary" @click="onSubmit">确认发送</el-button>
			  </div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				height:0,
				// 抽屉.
				drawer: false,
				direction:'rtl',
				title: '',
				content: '',
				labelPosition: 'left',
				// 商户选择器
				traderSed: [],
				traders: [],
				// 通道选择器
				paywaySed: [],
				payways: [],
				// 图片上传
				uploadFiles: [],
				// 多选
				checked: false,
				
			}
		},
		created() {
			this.getNoticeList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			beforeUpload(file) {
			  // 检查是否已经上传了一张图片
			  if (this.uploadFiles.length >= 1) {
				this.$message.error('只能上传一张图片');
				return false; // 返回 false，阻止上传
			  }
			  // 拦截文件上传操作，将文件暂时存储在 uploadFiles 数组中
			  this.uploadFiles.push(file);
			  return false; // 返回 false，阻止自动上传
			},
			handleChange(file, fileList) {
			  this.uploadFiles = fileList; // 更新文件列表
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
				  .then(_ => {
					done();
					this.close();
				  })
				  .catch(_ => {});
			},
			// 打开抽屉
			openDrawer(){
				this.drawer = true;
				this.getTraders()
				this.getPayways()
			},
			// 消息列表
			getNoticeList(){
				this.$api.cahtNoticeList({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					if (res.status === 1) {
						this.tableData = res.data.data	
						this.total = res.data.total
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			// 获取绑定商户列表
			getTraders(itemid){
				this.$api.getNoticeTraders({}).then(res => {
					if (res.status === 1) {
						this.traders = res.data		
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			// 获取绑定通道列表
			getPayways(itemid){
				this.$api.getNoticePayways({}).then(res => {
					if (res.status === 1) {
						this.payways = res.data		
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.getNoticeList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.getNoticeList()
			},
			close() {
				this.drawer = false;
				this.title = '';
				this.content = '';
				this.traderSed = []
				this.paywaySed = []
				this.traders=[]
				this.payways=[]
				this.checked= false
				this.uploadFiles = []
			},
			//新增
			onSubmit() {
				// 通知消息
				if (isEmpty(this.title, '请输入标题')) {
					return
				}
				// 通知消息
				if (isEmpty(this.content, '请输入内容')) {
					return
				}
				
				let username = local.get('fourusername');
				let tid = local.get('fourtid')
				  const formData = new FormData();
				  
				  formData.append('username', username);
				  formData.append('tid', tid);
				  
				  formData.append('title', this.title);
				  formData.append('content', this.content);
				  formData.append('trader_list', JSON.stringify(this.traderSed));
				  formData.append('payway_list', JSON.stringify(this.paywaySed));
				  formData.append('is_rand_user', this.checked==true?1:2);
			
			console.log(this.uploadFiles)
				  if (this.uploadFiles.length > 0) {
					formData.append('file', this.uploadFiles[0].raw);
				  }
				  
				this.dialogloading = true
				this.$api.telegramSendMessage(formData).then(res => {
					// console.log(res)
					this.dialogloading = false
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.close();
						this.page = 1;
						this.getNoticeList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			
			//设置权限
			resendMessage(id){
				
				this.$confirm('确认重发？')
				  .then(_ => {
					  this.dialogloading = true
					  let data = {
						id: id,
					  }
					  this.$api.resend(data).then(res=>{
						if(res.status == 1){
							this.dialogloading = false
							this.getNoticeList()
							this.$message({type:"success",message:res.msg})
						} else{						
							this.$message({type:"error",message:res.msg})
						}
					  }).catch(error=>{
						this.dialogloading = false
					  })
					  
					  
				  })
				  .catch(_ => {});
				
				
			},
			
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	.powerItem{
		display: inline-block;
		width: 33%;
		text-align: left;
		margin-bottom: 20px;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
	   
	   .drawer-content {
	     max-height: calc(100vh - 150px);
	     overflow-y: auto;
	     padding: 20px;
	   }
	   
	   .drawer-footer {
	     // position: fixed;
	     bottom: 0;
	     width: calc(100% - 50px);
	     background: #fff;
	     padding: 10px 20px;
	     box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1);
	     text-align: center;
	   }
</style>
